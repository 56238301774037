import Handlebars from 'handlebars'
import moment from 'moment-timezone'
import {DurationInputArg2} from 'moment-timezone'
import parsePhoneNumber from 'libphonenumber-js'
import currency from 'currency.js'
import iban from 'iban'
import {atPath} from './utils'

Handlebars.registerHelper('formatDate', (datetime: Date | string, format = 'DD/MM/YYYY') =>
  datetime
    ? moment(datetime).format(typeof format === 'string' ? format : 'DD/MM/YYYY')
    : moment().format(typeof format === 'string' ? format : 'DD/MM/YYYY')
)

Handlebars.registerHelper(
  'dateOperator',
  (
    datetime: Date | string,
    op: 'add' | 'subtract',
    value: string | number,
    unit: DurationInputArg2
  ) => {
    if (
      !op ||
      !['add', 'subtract'].includes(op) ||
      !value ||
      typeof value != 'string' ||
      !unit ||
      typeof unit != 'string'
    )
      return null

    const v = parseInt(value)
    const date = datetime ? moment(datetime) : moment()

    switch (op) {
      case 'add':
        return date.add(v, unit).format()
      case 'subtract':
        return date.subtract(v, unit).format()
      default:
        return date.format()
    }
  }
)

Handlebars.registerHelper('formatPhone', (phone: string) =>
  parsePhoneNumber(phone)?.formatNational()
)
Handlebars.registerHelper('formatCurrency', (amount) =>
  currency(amount, {symbol: '€', separator: ' ', decimal: ',', pattern: '#!'}).format()
)

Handlebars.registerHelper('ph', (...params) => {
  params.pop() // pop options
  const ph = params.length === 3 ? params.pop() : '▯▯▯'
  const [value, res] = params

  return value ? res : ph
})

Handlebars.registerHelper('uppercase', (str = '') => str.toUpperCase())
Handlebars.registerHelper('lowercase', (str = '') => str.toLowerCase())
Handlebars.registerHelper('capitalize', (str = '') => str.capitalize())
Handlebars.registerHelper('json', (o) => JSON.stringify(o))
Handlebars.registerHelper('incr', (value): number => parseInt(value) + 1)
Handlebars.registerHelper('decr', (value): number => parseInt(value) - 1)
Handlebars.registerHelper('len', (value): number => Object.keys(value).length)
Handlebars.registerHelper('typeof', (value): string => typeof value)
Handlebars.registerHelper('oid', (value): string =>
  typeof value === 'string' ? value : value?._id
)
Handlebars.registerHelper('equals', function (this: object, a, b, opts): boolean {
  return opts[a == b ? 'fn' : 'inverse'](this as object)
})

Handlebars.registerHelper(
  'maths',
  (
    lvalue: string | number,
    operator: '+' | '-' | '*' | '/' | '%',
    rvalue: string | number
  ): number => {
    lvalue = parseFloat(`${lvalue}`)
    rvalue = parseFloat(`${rvalue}`)
    return {
      '+': lvalue + rvalue,
      '-': lvalue - rvalue,
      '*': lvalue * rvalue,
      '/': lvalue / rvalue,
      '%': lvalue % rvalue
    }[operator]
  }
)

Handlebars.registerHelper('toLowerCase', (v = '') => v.toLowerCase())
Handlebars.registerHelper('toUpperCase', (v = '') => v.toUpperCase())
Handlebars.registerHelper('toUpperCaseFirst', (v = '') => v.toUpperCaseFirst())
Handlebars.registerHelper('capitalize', (v = '') => v.capitalize())
Handlebars.registerHelper('parseIban', (v = '') => {
  if (!v || !iban.isValid(v)) return null
  return iban.toBBAN(v, ',').split(',')
})

Handlebars.registerHelper('find', (array, path, key, index = 0) => {
  if (
    !array ||
    !Array.isArray(array) ||
    !key ||
    typeof key != 'string' ||
    !path ||
    typeof path != 'string'
  )
    return null

  if (!index || typeof index != 'number') index = 0

  const res = array.filter((obj) => atPath(obj, path) === key)
  if (res.length) return res[index]
  return null
})

const reduceOp = function (args: Array<any>, reducer: (current: any, acc: any) => boolean) {
  args = Array.from(args)
  args.pop() // => options
  const first = args.shift()
  return args.reduce(reducer, first)
}

Handlebars.registerHelper({
  eq(...params) {
    return reduceOp(params, (a, b) => a === b)
  },
  ne(...params) {
    return reduceOp(params, (a, b) => a !== b)
  },
  lt(...params) {
    return reduceOp(params, (a, b) => a < b)
  },
  gt(...params) {
    return reduceOp(params, (a, b) => a > b)
  },
  lte(...params) {
    return reduceOp(params, (a, b) => a <= b)
  },
  gte(...params) {
    return reduceOp(params, (a, b) => a >= b)
  },
  and(...params) {
    return reduceOp(params, (a, b) => a && b)
  },
  or(...params) {
    return reduceOp(params, (a, b) => a || b)
  }
})

export default (source: any, data: any, options?: any): string => {
  const template = Handlebars.compile(source, options)
  return template(data)
}
